interface CheckProps {
  ufFornecedor: string;
  ufLoja: string;
}

export type TOperacao = 'ESTADUAL' | 'INTERESTADUAL';

export const checkEstadualInterestadual = ({
  ufFornecedor,
  ufLoja,
}: CheckProps): TOperacao => {
  if (ufFornecedor === ufLoja) return 'ESTADUAL';
  return 'INTERESTADUAL';
};
