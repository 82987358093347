import React from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import Separator from '~/components/Separator';
import { Container } from './styles';

interface FormProps {
  label: string;
  eventKey: string;
  onChange(newEventKey: string): void;
}
/**
 * @param eventKey 0 para fechado e 1 para aberto
 * @returns onChange retorna novo valor para o eventKey
 */
export const CustomSeparator: React.FC<FormProps> = ({
  label,
  eventKey,
  onChange,
  children,
}) => {
  return (
    <Container
      isOppened={eventKey}
      onClick={() => {
        eventKey === '1' ? onChange('0') : onChange('1');
      }}
    >
      <Separator
        color="#57069e"
        fontSize="16px"
        labelText={label}
        childrenWidth="auto"
        sidePadding="0px"
      >
        {children && children}
        {!children && (
          <button type="button" className="btn-collapse">
            {eventKey === '1' && (
              <BiChevronDown
                color="#fff"
                size={20}
                style={{ marginTop: '-2px' }}
              />
            )}
            {eventKey === '0' && (
              <BiChevronUp
                color="#fff"
                size={20}
                style={{ marginTop: '-2px' }}
              />
            )}
          </button>
        )}
      </Separator>
    </Container>
  );
};
