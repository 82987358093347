import { ItemNFProps } from '../../protocols/ItemNFProps';
import { CapaNFProps } from '../../protocols/NfeProps';

export const cfopEnergia = (
  stateNf: CapaNFProps,
  stateItens: ItemNFProps[],
): boolean => {
  let result = false;

  stateItens.forEach((item) => {
    if (item.cfop === null) return;

    if (
      (stateNf.especie.label.trim().toUpperCase() === 'NFCEE' &&
        item.cfop !== 1253 &&
        item.cfop !== 2253 &&
        item.cfop !== 1252 &&
        item.cfop !== 2252) ||
      (stateNf.especie.label.trim().toUpperCase() !== 'NFCEE' &&
        stateNf.especie.label.trim().toUpperCase() !== 'NFE' &&
        (item.cfop === 1253 ||
          item.cfop === 2253 ||
          item.cfop === 1252 ||
          item.cfop === 2252))
    )
      result = true;
  });

  return result;
};
