import styled from 'styled-components';

interface Props {
  isOppened: string;
}

export const Container = styled.div<Props>`
  cursor: pointer;

  .btn-collapse {
    /* /* width: 36px; */
    /**
    Manter as duas propriedades abaixo comentadas para caso queira voltar ao modelo anterior
    width: ${(props) => (props.isOppened === '0' ? '36px' : '1px')};
    padding: ${(props) =>
      props.isOppened === '0' ? '1px 8px 1px 8px' : '1px 2px 1px 2px;'};
    */
    padding: 1px 8px 1px 8px;
    float: right;
    background-color: #8850bf;
    border: none;
    border-radius: 2px;
    /* margin-top: 0px; */
    transition: 0.2s;
    color: #fcfcfe;

    &:focus {
      outline: none !important;
      outline: 0px !important;
      -webkit-appearance: none;
      appearance: none;
      box-shadow: none !important;
    }
  }
  .btn-collapse:hover {
    background-color: #57069e;
    color: #000;
    transition: 0.2s;
  }

  &:hover {
    .btn-collapse {
      width: 36px;
      padding: 1px 8px 1px 8px;
    }
  }
`;
