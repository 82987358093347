export interface IProduto {
  cod_tributacao: string;
}

function formatarCPF(numero: string) {
  const numeroLimpo = numero.replace(/\D/g, '');

  if (numeroLimpo.length <= 3) {
    return numeroLimpo;
  }

  if (numeroLimpo.length <= 6) {
    return numeroLimpo.replace(/(\d{3})(\d{0,3})/, '$1.$2');
  }

  if (numeroLimpo.length <= 9) {
    return numeroLimpo.replace(/(\d{3})(\d{3})(\d{0,3})/, '$1.$2.$3');
  }

  return numeroLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, '$1.$2.$3-$4');
}

function formatarCNPJ(numero: string) {
  const numeroLimpo = numero.replace(/\D/g, '');

  if (numeroLimpo.length <= 2) {
    return numeroLimpo;
  }

  if (numeroLimpo.length <= 5) {
    return numeroLimpo.replace(/(\d{2})(\d{0,3})/, '$1.$2');
  }

  if (numeroLimpo.length <= 8) {
    return numeroLimpo.replace(/(\d{2})(\d{3})(\d{0,3})/, '$1.$2.$3');
  }

  if (numeroLimpo.length <= 12) {
    return numeroLimpo.replace(/(\d{2})(\d{3})(\d{3})(\d{0,4})/, '$1.$2.$3/$4');
  }

  return numeroLimpo.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/,
    '$1.$2.$3/$4-$5',
  );
}

export function maskDocumento(numero: string): string {
  const numeroLimpo = numero.replace(/\D/g, '');

  if (numeroLimpo.length <= 11) {
    return formatarCPF(numeroLimpo);
  }

  return formatarCNPJ(numeroLimpo);
}
