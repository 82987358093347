import { ItemNFProps } from '../../protocols/ItemNFProps';
import { TOperacao } from '../checkEstadualInterestadual';

export const verificarCFOPs = (
  items: ItemNFProps[],
  operacao: TOperacao,
): boolean => {
  const operacaoEstadual = operacao === 'ESTADUAL';
  const operacaoInterestadual = operacao === 'INTERESTADUAL';

  const hasIncompatibleCFOP = items.some((i) => {
    if (i.cfop === null) return false;

    const primeiroDigito = Math.floor(i.cfop / 1000);

    const cfopIsValid =
      (operacaoEstadual &&
        (primeiroDigito === 1 ||
          primeiroDigito === 5 ||
          primeiroDigito === 3)) ||
      (operacaoInterestadual &&
        (primeiroDigito === 2 || primeiroDigito === 6 || primeiroDigito === 3));

    return !cfopIsValid;
  });

  if (hasIncompatibleCFOP) return true;
  return false;
};
