import React from 'react';
import { ManutencaoFiscalContextProvider } from './ManutencaoFiscalContext';
import { ManutencaoFiscalContent } from './ManutencaoFiscalContent';

export const ManutencaoFiscal: React.FC = () => {
  return (
    <ManutencaoFiscalContextProvider>
      <ManutencaoFiscalContent />
    </ManutencaoFiscalContextProvider>
  );
};
